.booking-detail-title {
}
.booking-detail-content {
  .booking-detail-collapsible {
    position: relative;
    cursor: pointer;
    &:after {
      position: absolute;
      font-family: fontAwesome;
      content: '\f0d7';
      color: $dark-blue;
      right: 15px;
      top: 15px;
      transition: 0.3s all;
    }
    &.open {
      &:after {
        transform: rotate(180deg);
      }
    }
    .collapsible-detail {
      // display: none;
      // margin-top: 10px;
      overflow: hidden;

      max-height: 0;

      &.open-slidedown {
        margin-top: 10px;
        max-height: 100%;
        padding-top: 4px;
        // animation: slide 0.6s forwards;
        transition: all 0.3s ease;
      }
      &.open-slideup {
        max-height: 0;
        margin-top: 0;
        transition: all 0.3s ease;
        // animation: slideUp 0.6s forwards;
        // height: 0;
        // transform: translate(0,0);
        // animation: slideUp 0.6s forwards;
        // border: 1px solid #dadada;
        padding: 0;
        // height: 0 !important;
      }
    }
  }
  .flight-detail-wrapper {
    .flight-detail-time {
      font-size: 16px;
      font-family: Mulish;
      color: $dark-grey;
    }
    .flight-detail {
      .ic_sample {
        width: 40px;
        margin-top: 2em;
      }
      .separator-fd {
        width: 1px;
        height: 35px;
        background-color: $dark-blue;
        margin: 5px auto;
      }
    }
  }
  .pax-detail-wrapper {
    .pax-amount {
      font-size: 16px;
    }
    .pax-detail {
      ol {
        padding: 0 2em;
        margin: 0;
        li {
          font-size: 13px;
          padding-left: 5px;
          margin-bottom: 5px;
        }
      }
    }
  }
}