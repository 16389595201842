.form-group {
  label {
    color: #646464;
    display: block;
    margin-bottom: 2px;
  }

  select {
    background: none;
    // margin-top: 16px;
  }

  .form-control {
    // height: 30px;
    width: 100%;
    padding: 8px 10px;
    border: none;
    border-bottom: 1px solid #646464;
    margin-bottom: 18px;
    color: #646464;
    // border-radius: 4px;
    box-shadow: none;
    // font-family:  Mulish, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
    //   'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    font-size: 16px;
    &:focus {
      outline: 0;
    }
  }
}

.form-error-message {
  position: relative;
  top: -16px;
  font-size: 14px;
  color: #ff9494;
  margin-top: 12px;
  display: block;
}

// @media screen and (min-width: 800px) and (max-width: 1281px) {
//   input,
//   select {
//     font-family: initial !important;
//   }
// }

@media screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 2) and (min-resolution: 192dpi) {
  input,
  select {
    font-family: sans-serif !important;
  }
}

@include desktop-up {
  .modal-custom {
    width: 600px;
  }
}

@include mobile {
  .modal-custom {
    z-index: 1111;
  }

  .ReactModal__Overlay {
    z-index: 1111;
  }
}
