@keyframes slide {
  from {
    max-height: 0;
  }
  to {
    max-height: 500px;
  }
}

@keyframes slideUp {
  from {
    max-height: 500px;
  }
  to {
    max-height: 0;
  }
}
