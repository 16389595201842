.pv1{
	padding-top: 1em;
	padding-bottom: 1em;
}

.ph05{
	padding-left: .5em;
	padding-right: .5em;
}

.ph1{
	padding-left: 1em;
	padding-right: 1em;
}

.ph2{
	padding-left: 2em;
	padding-right: 2em;
}

.ph5px{
	padding-left: 5px;
	padding-right: 5px;
}

.pr1{
	padding-right: 1em;
}

.pr5px{
	padding-right: 5px;	
}

.pl1{
	padding-left: 1em;
}

.pl5px{
	padding-left: 5px;
}

.mb1{
	margin-bottom: 1em;
}

.mb2{
	margin-bottom: 2em;
}

.mb25px{
	margin-bottom: 25px;
}

.mb10px{
	margin-bottom: 10px;
}

.mb5px{
	margin-bottom: 5px;
}

.mr1{
	margin-right: 1em;
}