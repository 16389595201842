@mixin tablet-up {
  @media (min-width: 768px) {
    @content;
  }
}

@mixin desktop-up {
  @media (min-width: 1024px) {
    @content;
  }
}

@mixin mobile {
  @media (max-width: 768px) {
    @content;
  }
}

@mixin sectionDefault {
  section {
    padding: 95px 0 0;
    @include desktop-up {
      padding: 75px 0 0;
    }
    // margin: 120px 0;
  }
}
